@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.recurring-booking {
  $breakpoint: 600px;

  &__input {
    width: 100%;

    @media (min-width: 600px) {
      max-width: 280px;
    }
  }

  &__preview-heading,
  &__sessions-empty {
    display: flex;
    background-color: light.$background-secondary-default;
  }

  &__sessions-empty {
    gap: spacing.$m;
    flex-direction: column;
  }

  &__preview-heading {
    gap: spacing.$s;
    align-items: center;
    padding: spacing.$m;
    padding-bottom: spacing.$xxs;
    border-radius: corner-radius.$s corner-radius.$s 0 0;
  }

  &__sessions {
    overflow: hidden;
    background-color: light.$background-secondary-default;
    padding-top: spacing.$m;
    border-radius: 0 0 corner-radius.$s corner-radius.$s;
  }

  &__sessions-empty {
    padding: spacing.$m;
    padding-top: spacing.$xxs;
    border-radius: 0 0 corner-radius.$s corner-radius.$s;
  }
}
